body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.modal-input {
  border-bottom: 1px solid gray;
  outline: none;
}

html .pintura-editor {
  --color-background: 255, 255, 255;
  --color-foreground: 10, 10, 10;
}

@media (prefers-color-scheme: dark) {
  html {
    color: #fff;
    background: #111;
  }

  html .pintura-editor {
    --color-background: 10, 10, 10;
    --color-foreground: 255, 255, 255;
  }
}
